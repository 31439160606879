import * as React from 'react'
import Layout from '../components/Layout'
import LinkBar from '../components/LinkBar'
import {Link, withPrefix} from 'gatsby'
import {Helmet} from "react-helmet";

//Global stylesheet
import "../components/styles/global.css"


const Accidental404 = () => {
  return(
      <Layout pageTitle={"Accidental 404"}>

        <Helmet>
          <meta charSet="UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>

          <title>404 | Bangbiangyang Tourism</title>

          <meta name="author" content="aqq.xyz"/>
          <meta name="description" content="This page is missing and/or accessed in error."/>

          <meta property="og:title" content="404 | Bangbiangyang Tourism"/>
          <meta property="og:description" content="This page is missing and/or accessed in error."/>
          <meta property="og:url" content="bangbiangyang.aqq.xyz/404"/>
          <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

          <meta name="robots" content="noindex"/>

          <link rel="icon" href="../static/favicon.ico" />
        </Helmet>

        <LinkBar>
          <Link to="/">Home</Link> > <Link to="404">404</Link>
        </LinkBar>

        <div className="thinContentWrapper">
          <h1>
            404
          </h1>
          <p>
            This page is missing.
          </p>
          <p>
            <strong>This is probably a result of a broken or missing link.</strong> That's
              a bit of a problem, so maybe <Link class="standardLink" to="/contact">contact us</Link>.
          </p>
          <Link class="standardButton" to="/" style={{marginTop: 1 + 'rem'}}>Back to the homepage.</Link>
        </div>

      </Layout>
  )
}

export default Accidental404